export const camelCaseToDash = (obj) => camelCaseConverter(obj, "-");
export const camelCaseToSnake = (obj) => camelCaseConverter(obj, "_");
export const toCamelCase = (str) =>
  snakeCaseConverter(str).replace(/^\w/, (c) => c.toLowerCase());

export const toTitleCase = (str) =>
  snakeCaseConverter(str).replace(/^\w/, (c) => c.toUpperCase());

export const windowURL = new URL(location.pathname, location.href).href;

export const fetchWrapper = (method, url, payload = {}) => {
  const token = document.head.querySelector('meta[name="csrf-token"]').content;
  let body = JSON.stringify({ authenticity_token: token, ...payload });

  if (method.toUpperCase() === "GET") {
    for (const [key, value] of Object.entries(payload)) {
      url.searchParams.set(key, value);
    }
    body = null;
  }

  return fetch(url, {
    method,
    headers: {
      "Content-Type": "application/json",
    },
    body,
  });
};

export const fetchStream = (method, url, payload, postActionHook) => {
  fetchWrapper(method, new URL(url), payload).then((response) => {
    response.text().then((content) => {
      const stream = new DOMParser().parseFromString(content, "text/html");
      const template = stream.body.querySelector("template");
      document.body.appendChild(stream.body);

      if (postActionHook) {
        postActionHook(template.content);
      }
    });
  });
};

function snakeCaseConverter(str) {
  return str
    .split("--")
    .slice(-1)[0]
    .split(/[-_]/)
    .map((w) => w.replace(/./, (m) => m.toUpperCase()))
    .join("");
}

function camelCaseConverter(obj, separator = "_") {
  if (typeof obj != "object") return obj;

  for (const oldName in obj) {
    // Camel to separator
    const newName = oldName.replace(
      /([A-Z])/g,
      ($1) => separator + $1.toLowerCase()
    );

    // Only process if names are different
    if (newName !== oldName) {
      // Check for the old property name to avoid a ReferenceError in strict mode.
      if (obj.hasOwnProperty(oldName)) {
        obj[newName] = obj[oldName];
        delete obj[oldName];
      }
    }

    // Recursion
    if (typeof obj[newName] == "object") {
      obj[newName] = camelCaseConverter(obj[newName]);
    }
  }
  return obj;
}

export const toggleLoading = (element, transparent, message) => {
  element?.classList.toggle('loading')
  transparent && element?.classList.toggle('transparent-loader')
  if (!message) return

  const messageElement = document.querySelector('.js-loading-message')
  messageElement && (messageElement.textContent = message)
}
